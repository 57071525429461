.uploadFile {
    position: relative;
    border: 1px dashed $primary;
    border-radius: 10px;
    height: 100%;
    width: 230px;
    width: 100%;
    padding: 1rem;
    cursor: pointer;

    @include responsive(md-screen) {
        height: 230px;
        width: 230px;
    }

    @include responsive(xl-screen) {
        height: 230px;
        width: 230px;
    }

    .anticon.anticon-cloud-upload {
        font-size: 60px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &__close {
        position: absolute;
        top: -10px;
        cursor: pointer;
        right: -10px;
        width: 20px;
        height: 20px;
        background: #fff;
        outline: none;
        border: none;
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 50%;
        color: #fff;

        &:hover {
            opacity: 0.8;
        }
    }
}
