.ant-table {
    thead {
        .ant-table-cell {
            font-size: 14px;
            // padding: 12px !important;
            // background-color: $secondary !important;
        }
    }
    tbody {
        .ant-table-cell {
            font-size: 13px;
            // padding: 5px !important;
        }
    }
}

.ant-space {
    width: 100%;
}

.ant-input {
    height: 40px;

    &:focus {
        box-shadow: none !important;
    }
}

.ant-input-password {
    height: 40px;

    .ant-input {
        height: 100%;
        &:focus {
            box-shadow: none !important;
        }
    }
}

.ant-col.ant-form-item-label {
    padding-bottom: 2px;

    label {
        font-size: 14px;
        font-weight: 600;
        color: #444;
    }
}

.ant-select-in-form-item {
    height: 40px;
}

.ant-input-number {
    width: 100%;

    .ant-input-number-input-wrap {
        width: 100%;
        input {
            height: 40px;
            width: 100%;
        }
    }
}

.ant-picker {
    height: 40px;
    width: 100%;
}

.ant-btn {
    height: 40px !important;
    min-width: 40px !important;
}

.ant-modal {
    .ant-modal-header {
        margin-bottom: 24px;
        padding-bottom: 12px;
        border-bottom: 1px solid $white-5f5;
    }
}

.dot-menu-action {
    height: 20px !important;
    width: 20px !important;
    padding: 0;
    min-width: 20px !important;
}

.dropdown-action-table {
    min-width: 150px !important;
}

.card__content {
    .ant-card-head {
        margin-top: 1rem;
    }

    &.APPROVED {
        background-color: $green-8b3;
    }
    &.REJECTED {
        background-color: $red-9b9;
    }
    &.WAITING {
        background: linear-gradient(
            to right,
            #fff 20%,
            rgb(255, 213, 135) 40%,
            rgb(247, 197, 104) 55%,
            rgb(255, 193, 78) 70%,
            #fff 100%
        );
        background-size: 200% auto;
        animation: load 2s linear 0s infinite forwards;

        @keyframes load {
            to {
                background-position: -200% center;
            }
        }
    }
}

.ant-upload-select {
    width: 100% !important;
    height: 400px !important;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.ant-image {
    width: 100% !important;
}

.upload-avatar {
    .ant-upload-select {
        width: 150px !important;
        height: 150px !important;

        .ant-upload {
            padding: 10px;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: contain;
        }
    }
}

.ant-row-table {
    &-APPROVED {
        background-color: $green-8b3;

        .ant-table-cell-fix-right {
            background-color: $green-8b3;
        }
    }
    &-REJECTED {
        background-color: $red-9b9;

        .ant-table-cell-fix-right {
            background-color: $red-9b9;
        }
    }
    &-WAITING {
        background: linear-gradient(
            to right,
            #fff 20%,
            rgb(255, 213, 135) 40%,
            rgb(247, 197, 104) 55%,
            rgb(255, 193, 78) 70%,
            #fff 100%
        );
        background-size: 200% auto;
        animation: load 2s linear 0s infinite forwards;

        .ant-table-cell-fix-right {
            background: linear-gradient(
                to right,
                #fff 20%,
                rgb(255, 213, 135) 40%,
                rgb(247, 197, 104) 55%,
                rgb(255, 193, 78) 70%,
                #fff 100%
            );
            background-size: 200% auto;
            animation: load 2s linear 0.85s infinite forwards;
        }

        @keyframes load {
            to {
                background-position: -200% center;
            }
        }
    }
}

.custom__modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;

    .ant-modal-content {
        max-height: 100vh;
        padding: 0 !important;
        border-radius: 0 !important;

        .ant-modal-header {
            border-radius: 0 !important;
            padding: 12px 24px;
            margin-bottom: 12px;
        }

        .ant-modal-body {
            max-height: calc(100vh - 140px);
            min-height: calc(100vh - 140px);
            padding: 12px 24px;
            overflow-y: scroll;

            &::-webkit-scrollbar-thumb {
                width: 8px;
                background-color: $gray-d9d;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-button {
                width: 8px;
                border-radius: 4px;
            }

            &::-webkit-scrollbar {
                width: 8px;
            }
        }

        .ant-modal-footer {
            padding: 12px 24px;
        }
    }
}
