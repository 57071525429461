.header {
    background-color: #fff;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
    position: sticky;
    top: 0;
    margin-left: 80px;
    padding: 0 16px 0 0;
    display: flex;
    justify-content: space-between;
    z-index: 50;
    align-items: center;
    transition: all ease 0.2s;

    @include responsive(xl-screen) {
        padding: 0 24px 0 0px;
    }

    &.active {
        margin-left: 0px;

        @include responsive(xl-screen) {
            margin-left: 250px;
        }
    }

    &__select {
        width: 250px;
    }

    &__menu {
        &-toggle {
            display: block;
            padding: 0 1rem;

            &:hover {
                background: none !important;
            }

            &:hover svg {
                color: $primary;
                fill: $primary;
            }

            @include responsive(xl-screen) {
                padding: 0 24px;
            }
        }
    }

    &__profile {
        padding: 0.5rem;
        height: 40px;
        cursor: pointer;
        @include flex-center();
        outline: none;
        border: none;
        background: none;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }

        &-name {
            font-size: 16px;
            margin-left: 0.5rem;
            font-weight: 400;
        }
    }
}
