.login {
    @include flex-center();
    min-height: 100vh;
    &__wrapper {
        max-width: 414px;
        width: 414px;
    }

    &__btn {
        width: 100%;

        &-google {
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            color: white;
            margin-bottom: 10px;
            @include flex-center();
            gap: 1rem;

            img {
                height: 20px;
            }

            span {
                color: #000;
            }
        }
    }

    &__header {
        margin: 1.5rem 0;
    }

    &__title {
        font-size: 22px;
        margin-bottom: 12px;
    }
}
