.ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 400px;
    overflow-y: auto;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ck-focused {
    border-color: $primary !important;
}
