.textEditor {
    position: relative;

    &__group {
        position: absolute;
        top: -35px;
        right: 0;
    }

    &__gallery-btn {
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        height: 30px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &:active {
            background: rgba(0, 0, 0, 0.2);
        }

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &__preview {
        border: 1px solid $gray-d9d;
        padding: 1rem;
        border-radius: 8px;
    }
}
