.feedback {
    min-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
    .feedbackListing {
        height: 100%;
        padding: 0px !important;
        .ant-card-body {
            padding: 0px !important;
        }
        .ant-list-items {
            max-height: calc(100vh - 250px);
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: $gray-d9d;
                border-radius: 4px;
            }
        }
        .ant-list-item {
            border-block-end: none !important;
            padding: 0 !important;
        }

        &__item {
            display: flex !important;
            align-items: center !important;
            cursor: pointer;
            border-block-end: none !important;
            padding: 12px !important;

            &.active {
                background-color: $secondary-400;
            }

            &:hover {
                background-color: $secondary-400;
            }

            .ant-list-item-meta-title {
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-weight: 500;
            }

            .ant-list-item-meta-description {
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 14px !important;
                font-weight: 400;
            }
        }
    }
    .messageBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ant-card-body {
            padding: 0px !important;
            height: 100%;
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $white-9e9;
            padding: 12px !important;

            &-item {
                display: flex;
                align-items: center !important;

                .ant-list-item-meta-title {
                    font-weight: 500;
                    margin-left: 0.5rem;
                }
            }
        }
        &__list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        &__body {
            padding: 12px;
            flex: 1;
            max-height: calc(100vh - 300px);
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                width: 4px;
                background-color: $gray-d9d;
                border-radius: 4px;
            }
        }
        &__footer {
            padding: 12px;
            display: flex;
            border-top: 1px solid $white-9e9;
            gap: 12px;

            &-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                height: 40px;
                width: 40px;
            }
        }
    }
    .message {
        display: flex;

        &.isSender {
            justify-content: flex-end;

            .message__avatar {
                margin-right: 0xp;
                margin-left: 12px;
                order: 2;
            }
            .message__time {
                text-align: right;
            }
            .message__title {
                border-radius: 10px 0px 0px 10px;
                background-color: $secondary-400;
            }
        }

        &__avatar {
            margin-right: 12px;
        }

        &__title {
            border-radius: 0px 10px 10px 0px;
            padding: 8px 12px;
            font-size: 14px;
            position: relative;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(238, 238, 238);
        }

        &__time {
            font-size: 12px;
            color: rgb(149, 156, 169);
        }
    }
}
