.modalConfirmDelete {
    &__title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: 24px;
    }
    &__des {
        text-align: center;
        margin-bottom: 24px;
    }
}
