.sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;

    @include responsive(xl-screen) {
        z-index: 20;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .ant-menu-item {
        width: 100%;
        margin: 0;
        border-radius: 0;
    }

    .ant-menu-submenu-title {
        margin: 0;
        border-radius: 0;
        width: 100%;
    }

    &__logo {
        position: sticky;
        top: 0;
        z-index: 20;
        height: 64px;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px -1px;
    }
}
