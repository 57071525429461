* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.d {
    &-flex {
        display: flex;
    }
    &-block {
        display: block;
    }
    &-none {
        display: none;
    }
    &-inline-block {
        display: inline-block;
    }
    &-grid {
        display: grid;
    }
}

.items {
    &-start {
        align-items: start;
    }
    &-center {
        align-items: center;
    }
    &-end {
        align-items: end;
    }
}

.justify {
    &-start {
        justify-content: flex-start;
    }
    &-end {
        justify-content: flex-end;
    }
    &-between {
        justify-content: space-between;
    }
    &-center {
        justify-content: center;
    }
}

.gap {
    &-1 {
        gap: 0.25rem;
    }
    &-2 {
        gap: 0.5rem;
    }
    &-3 {
        gap: 0.75rem;
    }
    &-4 {
        gap: 1rem;
    }
    &-5 {
        gap: 1.25rem;
    }
    &-6 {
        gap: 1.5rem;
    }
    &-7 {
        gap: 1.75rem;
    }
    &-8 {
        gap: 2rem;
    }
    &-9 {
        gap: 2.25rem;
    }
    &-10 {
        gap: 2.5rem;
    }
    &-11 {
        gap: 2.75rem;
    }
    &-12 {
        gap: 3rem;
    }
}

.w {
    &-25 {
        width: 25%;
    }
    &-50 {
        width: 50%;
    }
    &-75 {
        width: 75%;
    }
    &-100 {
        width: 100%;
    }
}

.flex {
    &-1 {
        flex: 1;
    }
}

.fw {
    &-bold {
        font-weight: bold;
    }
    &-normal {
        font-weight: normal;
    }
    &-300 {
        font-weight: 300;
    }
    &-400 {
        font-weight: 400;
    }
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
    &-700 {
        font-weight: 700;
    }
}

.text {
    &-center {
        text-align: center;
    }
    &-start {
        text-align: start;
    }
    &-end {
        text-align: end;
    }
    &-uppercase {
        text-transform: uppercase;
    }
    &-capitalize {
        text-transform: capitalize;
    }
}

.rounded-full {
    border-radius: 50% !important;
}

.cursor {
    &-pointer {
        cursor: pointer;
    }
}

.m {
    &-0 {
        margin: 0;
    }
    &-1 {
        margin: 0.25rem;
    }
    &-2 {
        margin: 0.5rem;
    }
    &-3 {
        margin: 0.75rem;
    }
    &-4 {
        margin: 1rem;
    }
    &-5 {
        margin: 1.25rem;
    }
    &-6 {
        margin: 1.5rem;
    }
    &-7 {
        margin: 1.75rem;
    }
    &-8 {
        margin: 2rem;
    }
    &-9 {
        margin: 2.25rem;
    }
    &-10 {
        margin: 2.5rem;
    }
    &-11 {
        margin: 2.75rem;
    }
    &-12 {
        margin: 3rem;
    }

    &-t {
        &-0 {
            margin-top: 0;
        }
        &-1 {
            margin-top: 0.25rem;
        }
        &-2 {
            margin-top: 0.5rem;
        }
        &-3 {
            margin-top: 0.75rem;
        }
        &-4 {
            margin-top: 1rem;
        }
        &-5 {
            margin-top: 1.25rem;
        }
        &-6 {
            margin-top: 1.5rem;
        }
        &-7 {
            margin-top: 1.75rem;
        }
        &-8 {
            margin-top: 2rem;
        }
        &-9 {
            margin-top: 2.25rem;
        }
        &-10 {
            margin-top: 2.5rem;
        }
        &-11 {
            margin-top: 2.75rem;
        }
        &-12 {
            margin-top: 3rem;
        }
    }

    &-b {
        &-0 {
            margin-bottom: 0;
        }
        &-1 {
            margin-bottom: 0.25rem;
        }
        &-2 {
            margin-bottom: 0.5rem;
        }
        &-3 {
            margin-bottom: 0.75rem;
        }
        &-4 {
            margin-bottom: 1rem;
        }
        &-5 {
            margin-bottom: 1.25rem;
        }
        &-6 {
            margin-bottom: 1.5rem;
        }
        &-7 {
            margin-bottom: 1.75rem;
        }
        &-8 {
            margin-bottom: 2rem;
        }
        &-9 {
            margin-bottom: 2.25rem;
        }
        &-10 {
            margin-bottom: 2.5rem;
        }
        &-11 {
            margin-bottom: 2.75rem;
        }
        &-12 {
            margin-bottom: 3rem;
        }
    }

    &-l {
        &-0 {
            margin-left: 0;
        }
        &-1 {
            margin-left: 0.25rem;
        }
        &-2 {
            margin-left: 0.5rem;
        }
        &-3 {
            margin-left: 0.75rem;
        }
        &-4 {
            margin-left: 1rem;
        }
        &-5 {
            margin-left: 1.25rem;
        }
        &-6 {
            margin-left: 1.5rem;
        }
        &-7 {
            margin-left: 1.75rem;
        }
        &-8 {
            margin-left: 2rem;
        }
        &-9 {
            margin-left: 2.25rem;
        }
        &-10 {
            margin-left: 2.5rem;
        }
        &-11 {
            margin-left: 2.75rem;
        }
        &-12 {
            margin-left: 3rem;
        }
    }

    &-r {
        &-0 {
            margin-right: 0;
        }
        &-1 {
            margin-right: 0.25rem;
        }
        &-2 {
            margin-right: 0.5rem;
        }
        &-3 {
            margin-right: 0.75rem;
        }
        &-4 {
            margin-right: 1rem;
        }
        &-5 {
            margin-right: 1.25rem;
        }
        &-6 {
            margin-right: 1.5rem;
        }
        &-7 {
            margin-right: 1.75rem;
        }
        &-8 {
            margin-right: 2rem;
        }
        &-9 {
            margin-right: 2.25rem;
        }
        &-10 {
            margin-right: 2.5rem;
        }
        &-11 {
            margin-right: 2.75rem;
        }
        &-12 {
            margin-right: 3rem;
        }
    }

    &-x {
        &-0 {
            margin-left: 0;
            margin-right: 0;
        }
        &-1 {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
        }
        &-2 {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
        &-3 {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
        }
        &-4 {
            margin-left: 1rem;
            margin-right: 1rem;
        }
        &-5 {
            margin-left: 1.25rem;
            margin-right: 1.25rem;
        }
        &-6 {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
        &-7 {
            margin-left: 1.75rem;
            margin-right: 1.75rem;
        }
        &-8 {
            margin-left: 2rem;
            margin-right: 2rem;
        }
        &-9 {
            margin-left: 2.25rem;
            margin-right: 2.25rem;
        }
        &-10 {
            margin-left: 2.5rem;
            margin-right: 2.5rem;
        }
        &-11 {
            margin-left: 2.75rem;
            margin-right: 2.75rem;
        }
        &-12 {
            margin-left: 3rem;
            margin-right: 3rem;
        }
    }

    &-y {
        &-0 {
            margin-top: 0;
            margin-bottom: 0;
        }
        &-1 {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
        }
        &-2 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        &-3 {
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }
        &-4 {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        &-5 {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }
        &-6 {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
        &-7 {
            margin-top: 1.75rem;
            margin-bottom: 1.75rem;
        }
        &-8 {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        &-9 {
            margin-top: 2.25rem;
            margin-bottom: 2.25rem;
        }
        &-10 {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
        }
        &-11 {
            margin-top: 2.75rem;
            margin-bottom: 2.75rem;
        }
        &-12 {
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    &-auto {
        margin: auto;
    }

    &-auto-y {
        margin-top: auto;
        margin-bottom: auto;
    }

    &-auto-x {
        margin-left: auto;
        margin-right: auto;
    }

    &-auto-t {
        margin-top: auto;
    }

    &-auto-b {
        margin-bottom: auto;
    }

    &-auto-l {
        margin-left: auto;
    }

    &-auto-r {
        margin-right: auto;
    }

    &-auto-x {
        margin-left: auto;
        margin-right: auto;
    }
}

.p {
    &-0 {
        padding: 0;
    }
    &-1 {
        padding: 0.25rem;
    }
    &-2 {
        padding: 0.5rem;
    }
    &-3 {
        padding: 0.75rem;
    }
    &-4 {
        padding: 1rem;
    }
    &-5 {
        padding: 1.25rem;
    }
    &-6 {
        padding: 1.5rem;
    }
    &-7 {
        padding: 1.75rem;
    }
    &-8 {
        padding: 2rem;
    }
    &-9 {
        padding: 2.25rem;
    }
    &-10 {
        padding: 2.5rem;
    }
    &-11 {
        padding: 2.75rem;
    }
    &-12 {
        padding: 3rem;
    }

    &-t {
        &-0 {
            padding-top: 0;
        }
        &-1 {
            padding-top: 0.25rem;
        }
        &-2 {
            padding-top: 0.5rem;
        }
        &-3 {
            padding-top: 0.75rem;
        }
        &-4 {
            padding-top: 1rem;
        }
        &-5 {
            padding-top: 1.25rem;
        }
        &-6 {
            padding-top: 1.5rem;
        }
        &-7 {
            padding-top: 1.75rem;
        }
        &-8 {
            padding-top: 2rem;
        }
        &-9 {
            padding-top: 2.25rem;
        }
        &-10 {
            padding-top: 2.5rem;
        }
        &-11 {
            padding-top: 2.75rem;
        }
        &-12 {
            padding-top: 3rem;
        }
    }

    &-b {
        &-0 {
            padding-bottom: 0;
        }
        &-1 {
            padding-bottom: 0.25rem;
        }
        &-2 {
            padding-bottom: 0.5rem;
        }
        &-3 {
            padding-bottom: 0.75rem;
        }
        &-4 {
            padding-bottom: 1rem;
        }
        &-5 {
            padding-bottom: 1.25rem;
        }
        &-6 {
            padding-bottom: 1.5rem;
        }
        &-7 {
            padding-bottom: 1.75rem;
        }
        &-8 {
            padding-bottom: 2rem;
        }
        &-9 {
            padding-bottom: 2.25rem;
        }
        &-10 {
            padding-bottom: 2.5rem;
        }
        &-11 {
            padding-bottom: 2.75rem;
        }
        &-12 {
            padding-bottom: 3rem;
        }
    }

    &-l {
        &-0 {
            padding-left: 0;
        }
        &-1 {
            padding-left: 0.25rem;
        }
        &-2 {
            padding-left: 0.5rem;
        }
        &-3 {
            padding-left: 0.75rem;
        }
        &-4 {
            padding-left: 1rem;
        }
        &-5 {
            padding-left: 1.25rem;
        }
        &-6 {
            padding-left: 1.5rem;
        }
        &-7 {
            padding-left: 1.75rem;
        }
        &-8 {
            padding-left: 2rem;
        }
        &-9 {
            padding-left: 2.25rem;
        }
        &-10 {
            padding-left: 2.5rem;
        }
        &-11 {
            padding-left: 2.75rem;
        }
        &-12 {
            padding-left: 3rem;
        }
    }

    &-r {
        &-0 {
            padding-right: 0;
        }
        &-1 {
            padding-right: 0.25rem;
        }
        &-2 {
            padding-right: 0.5rem;
        }
        &-3 {
            padding-right: 0.75rem;
        }
        &-4 {
            padding-right: 1rem;
        }
        &-5 {
            padding-right: 1.25rem;
        }
        &-6 {
            padding-right: 1.5rem;
        }
        &-7 {
            padding-right: 1.75rem;
        }
        &-8 {
            padding-right: 2rem;
        }
        &-9 {
            padding-right: 2.25rem;
        }
        &-10 {
            padding-right: 2.5rem;
        }
        &-11 {
            padding-right: 2.75rem;
        }
        &-12 {
            padding-right: 3rem;
        }
    }

    &-x {
        &-0 {
            padding-left: 0;
            padding-right: 0;
        }
        &-1 {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
        &-2 {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &-3 {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
        &-4 {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &-5 {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
        &-6 {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        &-7 {
            padding-left: 1.75rem;
            padding-right: 1.75rem;
        }
        &-8 {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        &-9 {
            padding-left: 2.25rem;
            padding-right: 2.25rem;
        }
        &-10 {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
        &-11 {
            padding-left: 2.75rem;
            padding-right: 2.75rem;
        }
        &-12 {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &-y {
        &-0 {
            padding-top: 0;
            padding-bottom: 0;
        }
        &-1 {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
        &-2 {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        &-3 {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
        &-4 {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        &-5 {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
        &-6 {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        &-7 {
            padding-top: 1.75rem;
            padding-bottom: 1.75rem;
        }
        &-8 {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        &-9 {
            padding-top: 2.25rem;
            padding-bottom: 2.25rem;
        }
        &-10 {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }
        &-11 {
            padding-top: 2.75rem;
            padding-bottom: 2.75rem;
        }
        &-12 {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &-auto {
        padding: auto;
    }

    &-auto-y {
        padding-top: auto;
        padding-bottom: auto;
    }

    &-auto-x {
        padding-left: auto;
        padding-right: auto;
    }

    &-auto-t {
        padding-top: auto;
    }

    &-auto-b {
        padding-bottom: auto;
    }

    &-auto-l {
        padding-left: auto;
    }

    &-auto-r {
        padding-right: auto;
    }

    &-auto-x {
        padding-left: auto;
        padding-right: auto;
    }
}

.object {
    &-contain {
        object-fit: contain;
    }
    &-cover {
        object-fit: cover;
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &-1 {
        @extend .line-clamp;
        -webkit-line-clamp: 1;
    }
    &-2 {
        @extend .line-clamp;
        -webkit-line-clamp: 2;
    }
    &-3 {
        @extend .line-clamp;
        -webkit-line-clamp: 3;
    }
}

.tag {
    &-WAITING {
        background: linear-gradient(
            to right,
            #ffb829 20%,
            rgb(225, 148, 6) 40%,
            rgb(222, 149, 12) 55%,
            rgb(216, 141, 1) 70%,
            #feb12c 100%
        );
        background-size: 200% auto;
        animation: load 2s linear 0s infinite forwards;

        @keyframes load {
            to {
                background-position: -200% center;
            }
        }
    }
}
