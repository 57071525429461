.defaultLayout {
    min-height: 100vh;

    &__content {
        padding: 16px;
        transition: all ease 0.2s;

        @include responsive(xl-screen) {
            padding: 24px 24px 24px 104px;
        }

        &.active {
            padding: 16px;

            @include responsive(xl-screen) {
                padding: 24px 24px 24px 274px;
            }
        }
    }

    &__wrapper {
        background-color: #fafafb;
    }
}
