//USAGE
//EX: @inclue position(absolute, 100, 6, auto, auto){}
@mixin position($position, $top, $right, $bottom, $left) {
    position: $position;
    top: $top;
    right: $right;
    left: $left;
    bottom: $bottom;
}

//USAGE
//EX: @inclue flex-center(){}
@mixin flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin border($num, $color, $style: solid) {
    border: $num $style $color;
}

// RESPONSIVE - MIN-WIDTH - ANTD- xs -sm -md - lg -xl - xxl
// USE: @include respond(xs-screen) {}

@mixin responsive($breakpoint) {
    // screen < 576px
    @if $breakpoint==xs-screen {
        @media only screen and (max-width: 576px) {
            @content;
        }
    }

    // screen ≥ 576px
    @if $breakpoint==sm-screen {
        @media only screen and (min-width: 576px) {
            @content;
        }
    }

    // screen ≥ 768px
    @if $breakpoint==md-screen {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }

    // screen ≥ 992px
    @if $breakpoint==lg-screen {
        @media only screen and (min-width: 992px) {
            @content;
        }
    }

    // screen ≥ 1024px
    @if $breakpoint==xl-screen {
        @media only screen and (min-width: 1024px) {
            @content;
        }
    }

    // screen ≥ 1200pxx
    @if $breakpoint==xxl-screen {
        @media only screen and (min-width: 1200px) {
            @content;
        }
    }

    @if $breakpoint==xxxl-screen {
        @media only screen and (min-width: 1600px) {
            @content;
        }
    }
}
